// Если нам надо оплучить глобальные данные
// Если нам надо обработать данные используем мутации
import { mutations } from './mutations';

export default {
	getAllMedicalOrgsForRegions(regionId) {
		let params = {
			filters: [
				{
					fieldName: 'RegionId',
					filterType: 1,
					filterValueType: 1,
					value1: regionId
				}
			],
			sortOption: {
				fieldName: 'RegionName',
				sortType: 2
			},
			pageSize: 999,
			currentPage: 0
		};
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetMedicalOrgs', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getResultForThreeYear(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetResultForThreeYear', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRegionYearData(region) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Region/GetRegionYearData?regionId=${region}`)
				.then((res) => {
					resolve(res);
					mutations.yearMutations(res.data.availableYears);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
