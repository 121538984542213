<template>
	<div class="w-full">
		<div class="flex justify-between items-center">
			<h1 class="text-xl font-extrabold sm:text-2xl">Результаты независимой оценки за 1-3 года</h1>
			<div class="flex items-center">
				<div class="block-check">
					<span class="check-text" @click="openCheks = !openCheks">{{ selectedYears.join('/') }}</span>
					<div v-if="openCheks" class="dropList">
						<p>
							<span>Выберите года</span><br />
							не более трёх
						</p>
						<ul>
							<li v-for="item in yearArr" :key="item.year">
								<input @change="changeCheckBox()" type="checkbox" :disabled="item.disabled" name="label" :id="item.year" v-model="selectedYears" :value="item.year" /><label
									:for="item.year"
									>{{ item.year }}</label
								>
							</li>
						</ul>
					</div>
				</div>
				<Button text="Экспорт в Excel" icon="exel" @click.native="openDialogExport" />
			</div>
		</div>
		<div class="table-respons t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default" :style="includedMO.length > 9 ? 'height: calc(100vh - 320px)' : ''">
			<table class="table-fixed" :style="includedMO.length > 0 ? 'min-height: 550px' : ''">
				<colgroup>
					<col style="width: 4.5rem" />
					<col style="min-width: 18.8rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
					<col style="width: 4.5rem" />
				</colgroup>
				<thead>
					<tr :class="{ tr1__sticky: rowsVisible > 5 }">
						<th rowspan="3" class="text-center">П/П</th>
						<th rowspan="3" class="th-sort">
							<div class="flex">
								<span class="cursor-pointer" @click="switchFilterVisibility(0)">Наименование МО</span>
								<Icons v-if="params.filters.find((f) => f.fieldName === firstField[0].fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent
								v-on:change="switchFilterVisibility(null)"
								:filterOpen="filterOpen"
								position="left"
								:id-filter="0"
								:param="params"
								@query="getData"
								:fields="firstField"
								:item="firstField[0]"
							/>
						</th>
						<th :colspan="15" class="text-center"><p>Результаты НОК</p></th>
					</tr>
					<tr :class="{ tr2__sticky: rowsVisible > 5 }">
						<th v-for="(item, index) in fields" :key="'H-' + index" colspan="3" class="text-center">
							<p>{{ item.Name }}</p>
						</th>
					</tr>
					<tr :class="{ tr3__sticky: rowsVisible > 5 }">
						<th v-for="(item, index) in lastFields" :key="'H1-' + index" :colspan="selectedYears.length === 2 ? ((index + 1) % 2 !== 0 ? 2 : 0) : calculateColspan(selectedYears.length)">
							<div class="flex items-center justify-center">
								<p class="cursor-pointer underline" @click="switchFilterVisibilityTwo(index)">{{ item.Name }}</p>
								<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
							</div>
							<FilterComponent
								v-on:change="switchFilterVisibility(null)"
								:filterOpen="filterOpenTwo"
								position="right"
								:id-filter="index"
								:param="params"
								@query="getData"
								:fields="lastFields"
								:item="item"
							/>
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, i) in includedMO" :key="'R-' + i">
					<tr @click="openRow(item)" :class="{ 'bg-gray-30': item.medicalOrgId === opened, 'cursor-pointer': item.hasBranch }">
						<td style="padding: 10px; text-align: center">
							<span v-if="params.currentPage === 0">
								{{ i + 1 }}
							</span>
							<span v-if="params.currentPage > 0">
								{{ params.currentPage * rowsVisible + (i + 1) }}
							</span>
						</td>
						<td>
							<div class="flex items-center mb-2">
								<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
								<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
							</div>
							<p :class="{ underline: item.hasBranch }">{{ item.name }}</p>
						</td>
						<td v-for="(key, index) in lastFields" :key="'D-' + index" :colspan="selectedYears.length === 2 ? ((index + 1) % 2 !== 0 ? 2 : 0) : calculateColspan(selectedYears.length)">
							<p class="text-center">{{ item[key.fieldName] }}</p>
						</td>
					</tr>
					<template v-if="item.medicalOrgId === opened">
						<tr v-for="(subitem, j) in item.branches" :key="'B-' + j" :class="{ 'bg-gray-30': item.medicalOrgId === opened }">
							<td style="padding: 10px; text-align: center">
								{{ j + 1 }}
							</td>
							<td>
								<div class="flex items-center mb-2">
									<div v-if="subitem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
									<div v-if="subitem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
								</div>
								<p>{{ subitem.name }}</p>
							</td>
							<td v-for="(key, index) in lastFields" :key="'S-' + index">
								<p>{{ subitem[key.fieldName] }}</p>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<div v-if="!loadingTable && includedMO.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
				<span class="w-full">Данных нет...</span>
			</div>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />

		<PopUp :open="openExport" size="700px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col overflow-auto">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="upLoadExcel(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
import Icons from '@/components/Icons.vue';
// Глобальные данные стора
import { getters, mutations, methods } from '@/store/store';
// import _ from 'lodash';
// Локальные данные стора
import actions from '../store/actions';
import { gettersLocaly } from '../store/getters';
import loadingComponent from '@/components/Loading.vue';
import PopUp from '@/components/PopUp.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';

export default {
	name: 'TableIndependentResult',
	components: {
		loadingComponent,
		Button,
		FilterComponent,
		Pagination,
		Icons,
		PopUp,
		CheckboxGroup
	},
	data() {
		return {
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			openExport: false,
			openDelet: false,
			openCheks: false,
			open: false,
			filterOpen: null,
			filterOpenTwo: null,
			popupTitle: '',
			yearArr: [],
			allRows: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'Years',
						filterType: 9,
						filterValueType: 1,
						values: []
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				columns: [],
				pageSize: this.$MaxPageSize,
				currentPage: 0
			},
			selectedYears: ['2020', '2021', '2022'],
			firstField: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				}
			],
			lastFields: [],
			first: true,
			fields: [
				{
					Name: 'Итоговые результаты',
					fieldName: 'totalRes',
					exportName: 'TotalResults',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulatorRes',
					exportName: 'Ambulatory',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionarRes',
					exportName: 'Stationary',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'sanatoriatRes',
					exportName: 'Sanatorium',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatricRes',
					exportName: 'Psychiatric',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			fieldsDownloadExcell: [],
			includedMO: [],
			opened: null,
			loadToExcell: false,
			loadingTable: false
		};
	},
	computed: {
		...getters,
		...gettersLocaly
	},
	methods: {
		...mutations,
		...actions,
		...methods,
		calculateColspan(length) {
			if (length === 1) return 3;
			if (length === 2) return 2;
			return 0;
		},
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalOrgId) {
					this.opened = null;
				} else {
					this.opened = item.medicalOrgId;
				}
			}
		},
		changeCheckBox() {
			if (this.selectedYears.length === 3) {
				this.yearArr.map((item) => {
					let disabled = this.selectedYears.some((year) => year === item.year);
					if (!disabled) {
						item.disabled = true;
					} else {
						item.disabled = false;
					}
					return item;
				});
			} else if (this.selectedYears.length === 1) {
				this.yearArr.map((item) => {
					let disabled = this.selectedYears.some((year) => year === item.year);
					if (disabled) {
						item.disabled = true;
					} else {
						item.disabled = false;
					}
					return item;
				});
			} else {
				this.yearArr.map((item) => {
					item.disabled = false;
					return item;
				});
			}
			this.getData(this.params, true);
		},
		upLoadExcel(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					'MedicalOrgs/GetResultForThreeYearExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Результаты независимой оценки.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		closeOpenExport() {
			this.openExport = false;
			this.fieldsDownloadExcell = [];
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterOpenTwo = null;
		},
		switchFilterVisibilityTwo(key) {
			this.filterOpenTwo = this.filterOpenTwo === key ? null : key;
			this.filterOpen = null;
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;

				let yearsFilter = this.params.filters.find((x) => x.fieldName === 'Years');
				yearsFilter.values = this.selectedYears;

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getResultForThreeYear(this.params).then((res) => {
					this.allRows = res.data.data;
					this.includedMO = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.firstField[0].list = res.data.searchData;

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
					this.lastFields = [];

					this.fields.forEach((item) => {
						this.yearArr.forEach((year) => {
							let FN = `${item.fieldName}_${year.year}`,
								name = year.year,
								SB = null,
								val1 = '',
								val2 = null;

							if (this.params.sortOption.sortType && this.params.sortOption.fieldName === FN) {
								SB = this.params.sortOption.sortType;
							}

							if (this.params.filters.length > 2) {
								for (let i = 0; i < this.params.filters.length; i += 1) {
									if (this.params.filters[i].fieldName === FN) {
										val1 = this.params.filters[i].value1;
										if (this.params.filters[i].value2) val2 = this.params.filters[i].value2;
									}
								}
							}

							let isYear = this.selectedYears.some((key) => {
								return key === year.year;
							});

							if (isYear) {
								this.lastFields.push({
									Name: name,
									fieldName: FN,
									filterType: 1,
									filterValueType: 2,
									value1: val1,
									value2: val2,
									sortBy: SB,
									label1: 'По возрастанию',
									label2: 'По убыванию'
								});
							}
						});
					});
				});
			} else {
				this.includedMO = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		openDialogExport() {
			this.fieldsDownloadExcell.push({ title: 'Филиал', id: 'Branch', checked: true });
			this.fieldsDownloadExcell.push({ title: 'Наименование МО', id: 'MedOrgName', checked: true });

			for (let i = 0; i < this.fields.length; i += 1) {
				for (let k = 0; k < this.selectedYears.length; k += 1) {
					this.fieldsDownloadExcell.push({
						title: `Результаты независимой оценки: ${this.fields[i].Name}: ${this.selectedYears[k]}`,
						id: `${this.fields[i].exportName}_${k + 1}`,
						checked: true
					});
				}
			}

			this.openExport = true;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;

		this.getRegionYearData(this.region.id).then((res) => {
			this.yearArr = [];
			this.selectedYears = [];
			res.data.availableYears.forEach((item) => {
				this.yearArr.push({
					year: item.toString(),
					disabled: null
				});
				if (this.selectedYears.length < 3) {
					this.selectedYears.push(item.toString());
				}
			});
			this.changeCheckBox();
		});
	}
};
</script>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	height: 53px;
	top: 0;
	position: sticky;
	z-index: 7;
}
.tr2__sticky {
	background-color: #f9fafb;
	height: 53px;
	top: 53px;
	position: sticky;
	z-index: 6;
}
.tr3__sticky {
	background-color: #f9fafb;
	height: 53px;
	top: 106px;
	position: sticky;
	z-index: 6;
}

.check-text {
	width: 100%;
	height: 100%;
	line-height: 44px;
}
.block-check {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 20px;
	gap: 4px;
	width: 209px;
	height: 44px;
	background: #f8f9fd;
	border: 2px solid #3377ff;
	border-radius: 10px;
	position: relative;
	margin-right: 20px;
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	color: #3377ff;
}

.block-check .dropList {
	position: absolute;
	right: 0;
	top: 100%;
	z-index: 999;
	padding: 20px;
	background: #fff;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
	border-radius: 10px;
}
.block-check .dropList p {
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}
.block-check .dropList p span {
	text-transform: uppercase;
}

.block-check .dropList li {
	margin-bottom: 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #6a6e83;
	display: flex;
	align-items: center;
}

.block-check .dropList li input {
	width: 20px;
	height: 20px;
	margin-right: 14px;
}
.table-respons td,
.table-respons th {
	padding: 1rem;
	text-align: left;
	vertical-align: center;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
	position: relative;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons table {
	width: 100%;
}

.table-respons .th-sort {
	text-decoration-line: underline;
	/* cursor: pointer; */
}

.bold td {
	font-weight: 600;
}

.table-respons {
	background: #fff;
	border-radius: 10px 10px 0 0;
	min-height: 385px;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.table-respons .filial {
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #fff;
	display: block;
	width: 110px;
	line-height: 28px;
	margin-bottom: 10px;
	text-align: center;
	background: #a8b1ce;
	border-radius: 4px;
}

.table-respons .active td,
.table-respons .filial-row td {
	border-bottom: 1px solid rgba(168, 177, 206, 0.2);
	border-right: 1px solid rgba(168, 177, 206, 0.2);
}

th.text-center {
	text-align: center;
}

.active {
	background: #f2f5ff;
}

.active .th-sort {
	color: #3377ff;
}

.filial-row {
	background: #f2f5ff;
}
</style>
